<template>
  <section class="services" id="services" data-view>
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>

    <div class="services__content">
      <div class="services__top">
        <h2 class="section__title services__title iosTitle type2">
          <span class="text-wrapper">
            <span class="letters">
              {{ `${$t('services.title[0]') + '&nbsp;'}` }}
            </span>
          </span>
          <span class="text-wrapper">
            <span class="letters">
              {{ $t('services.title[1]') }}
            </span>
          </span>
        </h2>
        <div class="services__description">{{ $t('services.description') }}</div>
      </div>
      <div class="services__list">
        <div class="services__item appear cursor-hover" @click="toLink('/services/clm')">
          <div class="services__item--title">
            <a class="underline">
              {{ $t('services.list.clm.name') }}
            </a>
          </div>
          <div class="services__item--details">
            <img class="services__img" src="@/assets/img/services/e-Detailing.png" alt="">
            <p class="services__item--text">{{ $t('services.list.clm.details') }}</p>
            <svg class="services__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
        <div class="services__item appear cursor-hover" @click="toLink('/services/medical-web')">
          <div class="services__item--title">
            <a class="underline">
              {{ $t('services.list.medicalWeb.name') }}
            </a>
          </div>
          <div class="services__item--details">
            <img class="services__img" src="@/assets/img/services/MedicalWEB.png" alt="">
            <p class="services__item--text">{{ $t('services.list.medicalWeb.details') }}</p>
            <svg class="services__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="services__item appear cursor-hover" @click="toLink('/services/medical-vr')">
          <div class="services__item--title">
            <a class="underline">
              {{ $t('services.list.medicalVR.name') }}
            </a>
          </div>
          <div class="services__item--details">
            <img class="services__img" src="@/assets/img/services/MedicalVR.png" alt="">
            <p class="services__item--text">{{ $t('services.list.medicalVR.details') }}</p>
            <svg class="services__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <div class="services__item appear cursor-hover" @click="toLink('/services/medical-explainer')">
          <div class="services__item--title">
            <a class="underline">
              {{ $t('services.list.medicalExplainer.name') }}
            </a>
          </div>
          <div class="services__item--details">
            <img class="services__img" src="@/assets/img/services/Explainer.png" alt="">
            <p class="services__item--text">{{ $t('services.list.medicalExplainer.details') }}</p>
            <svg class="services__item--icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.4531 11.5684L30.5677 11.5686L30.5677 26.6965" stroke="black" stroke-linecap="round"/>
              <path d="M30.2231 11.8588L14.0016 28.0807" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </section>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/NewFooter.vue';
import anime from 'animejs';
import {mapMutations} from 'vuex';

export default {
  name: 'services',
  components: {
    Header,
    Footer,
  },
  methods: {
    ...mapMutations([
      'setCurrentPage',
    ]),

    toLink(link) {
      this.$router.push(link)
    },

    handleLink(e, list) {
      e.preventDefault();
      e.target.classList.add('activeItem');
      list.classList.add('nonActive');
      const hrefAtr = e.target.getAttribute('href');
      setTimeout(() => {
        this.$router.push(hrefAtr);
      }, 1000);
    },
  },
  mounted() {
    this.setCurrentPage('services');
    window.scrollTo(0, 0);

    const list = document.querySelector('.services__list');
    list.addEventListener('click', (e) => {
      this.handleLink(e, list);
    });

    setTimeout(() => {
      const services = document.querySelector('#services');
      const textWrapperAll = services.querySelectorAll('.text-wrapper .letters');
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (!Number.isInteger(index / 2)) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter strong'>$&</span>");
        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({
          loop: false,
        }).add({
          targets: '.text-wrapper .letter',
          translateY: ['1.2em', 0],
          translateZ: 0,
          duration: 1000,
          delay: (el, i) => i * 50,
        });
      });
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/animation.scss";

.services {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.section {


  &__title {
    font-size: 60px;
    line-height: 64px;
    font-weight: 300;
    text-align: left;
    color: var(--text-secondary-color);
    position: relative;
    margin-bottom: 0;

    .text-wrapper {
      position: relative;
      display: inline-block;
      overflow: hidden;
      padding: 0.1em 0.15em .1em 0;

      &:nth-of-type(2) {
        padding-bottom: 0.15em;
      }

    }

    ::v-deep .letter {
      font-size: 60px;
      line-height: 64px;
      display: inline-block;

      &.strong {
        font-family: "TimesNewRoman-I";
        font-size: 80px;
        font-style: italic;
        color: var(--footer-text-color);
      }
    }
  }
}

.services {
  &__content {
    max-width: calc(1140px + 90px);
    width: 100%;
    margin: 0 auto;
    padding: 171px 45px 151px 45px;
  }

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 84px;
  }

  &__description {
    color: #92A1AE;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    opacity: .8;
    text-align: left;
    max-width: 84%;

    display: flex;
    align-items: center;
  }

  &__list {
    margin-top: 72px;
    transition: all .2s ease;

    @media screen and (min-width: 1023px) {
      &:hover {
        .services__item {
          opacity: 0.5;
        }
      }
    }

    &:active {
      .services__item {
        opacity: 0.5;
      }
    }
  }

  &__img {
    position: absolute;
    left: -2%;
    // top: 0%;
    opacity: 0;
    max-width: 300px;
    object-fit: contain;
    z-index: 2;
    transition: all ease .4s;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 84px;
    text-align: left;
    padding: 30px 0 30px 15px;
    align-items: center;
    position: relative;
    margin-top: -1px;
    transition: all .2s ease;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #92A1AE;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #92A1AE;
    }

    &:nth-of-type(1) {
      .services__item--text,
      .services__item--title,
      .services__item--icon,
      .services__item::after {
        animation-delay: 0s;
      }

    }

    &:nth-of-type(2) {
      .services__item--text,
      .services__item--title,
      .services__item--icon,
      .services__item::after {
        animation-delay: .4s;
      }
    }

    &:nth-of-type(3) {
      .services__item--text,
      .services__item--title,
      .services__item--icon,
      .services__item::after {
        animation-delay: .8s;
      }
    }

    &:last-of-type {
      // border-bottom: 1px solid #92A1AE;
      .services__item--text,
      .services__item--title,
      .services__item--icon,
      .services__item::after {
        animation-delay: 1s;
      }
    }

    &--title {
      color: var(--footer-text-color);
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      position: relative;
      width: fit-content;
      margin-right: 15px;

      .underline {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 1px), var(--footer-text-color) 1px);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    &--details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-secondary-color);
    }

    &--icon {
      transition: all .1s ease-in;
      width: 40px;
      max-width: 40px;
      min-width: 40px;

      path {
        stroke: var(--footer-text-color);
      }
    }

    &:hover,
    &:active {
      opacity: 1 !important;

      //.services__img {
      //  opacity: 1;
      //}

      .services__item--icon {
        transform: rotate(45deg);
      }
    }
  }
}

.appear {
  .services {
    &__item {
      &--title,
      &--text,
      &--icon {
        animation: growsUp .8s alternate backwards;
      }
    }
  }

  &.services__item::after {
    animation: widthProgress .8s alternate forwards;
  }

  &.services__item:nth-of-type(1)::before {
    animation: widthProgress .8s alternate forwards;
  }
}

@media screen and (max-width: 1600px) {
  .services {

    &__description {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .services {
    &__content {
      padding-top: 140px;
    }
  }
}

@media screen and (max-width: 960px) {
  .services {


    &__description {
      font-size: 14px;
    }

    &__list {
      margin-top: 39px;
    }

    &__item {
      grid-column-gap: 22px;
    }

    &__top {
      grid-column-gap: 14px;
    }
  }
  .section {
    &__title {
      font-weight: 300;
      font-size: 48px;
      line-height: 52px;

      .text-wrapper {
        padding: 0;

        &:nth-of-type(2) {
          padding-bottom: 10px;
        }
      }

      ::v-deep .letter {
        font-size: 48px;
        line-height: 52px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section {
    &__title {
      font-weight: 300;
      font-size: 40px;
      line-height: 12px;

      .text-wrapper {
        padding: 0;
      }

      ::v-deep .letter {
        font-size: 40px;
        line-height: 52px;

        &.strong {
          font-size: 66px;
          // line-height: 60px;
        }
      }
    }
  }
  .services {
    &__content {
      padding: 100px 45px 60px 45px;
    }

    &__list {
      &.nonActive {
        & > .services__item {
          opacity: .5;
        }

        & > .services__item.activeItem {
          opacity: 1;
        }
      }
    }

    &__item {
      padding: 18px 0;

      &--title {
        font-size: 20px;
        line-height: 28px;
      }

      &--text {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__img {
      display: none;
    }
  }
}

@media screen and (max-width: 660px) {
  .services {
    &__content {
      padding-top: 76px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__top {
      grid-template-columns: 1fr;
    }
  }
  .section {
    &__title {
      font-weight: 300;
      font-size: 28px;
      line-height: 40px;

      .text-wrapper {
        padding-bottom: 0 !important;
      }

      ::v-deep .letter {
        font-size: 28px;
        line-height: 40px;

        &.strong {
          font-size: 44px;
          // line-height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .services {
    &__content {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__list {
      margin-top: 32px;
    }

    &__item {
      grid-template-columns: 1fr;

      &--title {
        max-width: 80%;
      }

      &--text {
        max-width: 80%;
        margin-top: 16px;
      }

      &--details {
        position: relative;
      }

      &--icon {
        position: absolute;
        right: 0;
        bottom: calc(50% - 20px / 2);
      }

      &:last-of-type {
        .services__item--icon {
          bottom: calc(50% + 20px / 2);
        }
      }
    }
  }
}
</style>
